<template>
    <div class="p-2">

        <!-- NAV HEADER -->
        <div class="d-flex mt-2 mb-2">
            <ul class="m-auto nav nav-pills">
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="border-radius: 10px 0 0 10px;background-color: #1EB4F1;color:white;font-size: 16px;border:2px solid #FFAD32"
                        to="/activities">My Activities</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="color:#1B628C;background-color: white;border-radius: 0 10px 10px 0;font-size: 16px;border:2px solid transparent" 
                        to="/activities/pet"
                    >
                    Pet Activities</router-link>
                </li>
            </ul>
        </div>
        <!-- END NAV -->

        <div v-if="activities.length < 1">
            <img src="@/static/undraw_Dog_walking_re_l61p-removebg-preview.png" class="w-100" />
            <h2 class="text-white text-center">No Activities For Now</h2>
        </div>

        <!-- ACTIVITIES LIST -->
        <div class="d-flex flex-column" style="gap:20px">
            <template v-for="(date, key) in activities">
                <template v-for="item in date">
                    <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-if="item.type == 'training'">
                        <div class="d-flex align-items-center" style="gap:10px 20px">
                            <div class="h3 text-white mb-0">Training, {{ item.data.pet.pet_name }}</div>
                            <!-- <i class="bx bxs-file-plus text-primary ml-auto" style="font-size: 22px;"></i> -->
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ item.data.schedule.training.tr_name }}
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ moment(item.data.schedule.trs_date).format('dddd, DD MMMM YYYY') }}, {{ item.data.schedule.trs_time }}
                        </div>

                        <div class="text-white h5 font-weight-bolder" style="margin-top: .5em;">Details: </div>

                        <p class="text-white mb-0" style="margin-top: .5em;">
                            {{item.data.schedule.training.tr_details}}
                        </p> 
                    </div>

                    <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-else-if="item.type == 'walker'">
                        <div class="d-flex align-items-center" style="gap:10px 20px">
                            <div class="h3 text-white mb-0">{{item.data.service}}, {{ item.data.pet.pet_name }}</div>
                            <!-- <i class="bx bxs-file-plus text-primary ml-auto" style="font-size: 22px;"></i> -->
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ item.data.pet.pet_breed }}
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ moment(item.data.ws_date).format('dddd, DD MMMM YYYY') }}, {{ item.data.ws_time }}
                        </div>

                        <div class="text-white h4 font-weight-bolder" style="margin-top: .5em;">Note: </div>
                        <div class="text-white">
                            {{ item.data.ws_note }}
                        </div>
                    </div>

                    <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-else-if="item.type == 'volunteer'">

                        <img class="w-100 rounded mb-1" v-if="item.data.volunteer_event.shv_picture" style="max-height: 100px;object-fit: cover;" :src="item.data.volunteer_event.shv_picture" />

                        <div class="d-flex align-items-center" style="gap:10px 20px">
                            <div class="h3 text-white mb-0">Volunteering, {{ item.data.volunteer_event.shv_title }}</div>
                            <!-- <i class="bx bxs-file-plus text-primary ml-auto" style="font-size: 22px;"></i> -->
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ item.data.volunteer_event.shv_location }}
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ moment(item.data.volunteer_event.shv_date).format('dddd, DD MMMM YYYY') }}, {{ item.data.volunteer_event.shv_time }}
                        </div>

                        <div class="text-white h5 font-weight-bolder" style="margin-top: .5em;">Details: </div>

                        <p class="text-white mb-0" style="margin-top: .5em;">
                            {{item.data.volunteer_event.shv_details}}
                        </p> 
                        
                    </div>
                </template>
            </template>
        </div>
        <!-- END LIST -->

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'
import Cookies from 'universal-cookie'
let cookies = new Cookies()

export default {
    computed: {
        activities(){
            return store.state.activities.MY_ACTIVITIES
        }
    },
    methods: {

    },
    mounted(){
        store.dispatch('activities/GetMyActivities', {
            user_id: cookies.get('token')
        })
    },
    data(){
        return {
            moment
        }
    }
}

</script>